<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12">
      <div class="card-header border-0 justify-content-between">
        <b-form class="w-full">
          <b-form-group label="Features">
            <el-select
              class="w-full"
              v-model="formImportFinance.type"
              placeholder="Select Menu"
            >
              <el-option
                v-for="item in menuList"
                :key="item.value"
                :value="item.type"
                :label="item.label"
              />
            </el-select>
            <b-form-invalid-feedback
              :state="!v$.formImportFinance.type.$invalid"
              v-if="v$.formImportFinance.type.$errors[0]">
              <span>{{ v$.formImportFinance.type.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Upload File">
          <b-form-file
            v-model="formImportFinance.file"
            class="border-1 p-2"
            accept=".csv, .xlsx, .xls"
            ref="fileInput"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <div class="w-full flex flex-row justify-content-between">
            <span>*only .xlsx or .xls file allowed</span>
            <a :href="sampleFilePath" download><font-awesome-icon icon="download"/> Download example file</a>
          </div>
          </b-form-group>
        <div class="d-flex flex-row float-right">
          <el-button @click="confirmImportFin" class="mr-2 ml-2" size="small" type="primary">
            Submit
          </el-button>
        </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
UPLOAD_FINANCE
} from '@/store/modules/app_user';
import useVuelidate from '@vuelidate/core';
import popupErrorMessages from '@/library/popup-error-messages';
import axios from "axios";
import { required } from '@vuelidate/validators';

export default {
  components: {},
  name: "Finance",
  metaInfo: {
    title: "Finance",
  },
  setup() {
    const v$ = useVuelidate();
    return {
      v$,
    };
  },
  data() {
    return {
      showSuccessModal: false,
      menuList: [
        {
          value: 'mobile-pulsa-ppob-prabayar',
          label: 'Mobile Pulsa PPOB Prabayar',
          type: 'mobile-pulsa-ppob-prabayar',
        },
        {
          value: 'mobile-pulsa-ppob-pascabayar',
          label: 'Mobile Pulsa PPOB Pascabayar',
          type: 'mobile-pulsa-ppob-pascabayar',
        },
        {
          value: 'mobile-pulsa-voucher',
          label: 'Mobile Pulsa Voucher',
          type: 'mobile-pulsa-voucher',
        },
        {
          value: 'payment-gateway-espay',
          label: 'Payment Gateway ESPAY',
          type: 'payment-gateway-espay',
        },
        {
          value: 'payment-gateway-xendit',
          label: 'Payment Gateway Xendit',
          type: 'payment-gateway-xendit',
        },
        {
          value: 'payment-gateway-mbayar',
          label: 'Payment Gateway M-Bayar',
          type: 'payment-gateway-mbayar',
        },
        {
          value: 'ultra-voucher',
          label: 'Ultra Voucher',
          type: 'ultra-voucher',
        },
        {
          value: 'payment-gateway-dipay',
          label: 'Payment Gateway Dipay',
          type: 'payment-gateway-dipay',
        }  
      ],
      isLoading: false,
      permissions: [],
      showModalCreate: false,
      importedData: [],
      selectedMenuItem: '',
      showConfirmationModal: false,
      loading: {
        upload_data_finance: false,
        import_data: false,
      },
      formImportFinance: {
        type: '',
        file: null,
      },
      v$: {
        formImportFinance: {
          type: {
            $model: '',
            $invalid: false,
            $errors: [],
          },
          file: {
            $invalid: false,
            $errors: [],
          },
        },
      },
      importSuccess: false,
      importMessage: '',
    };
  },
  validations() {
    return {
      formImportFinance: {
        type: { required },
        file: { required },
      },
    };
  },
  computed: {
    sampleFilePath() {
      switch (this.formImportFinance.type) {
        case 'payment-gateway-espay':
          return require('@/assets/samples/Sample-Import-Report-ESPAY.xlsx');
        case 'payment-gateway-xendit':
          return require('@/assets/samples/Sample-Import-Report-Xendit.xlsx');
        case 'mobile-pulsa-ppob-prabayar':
          return require('@/assets/samples/Sample-Import-Report-MP-PPOB-Prabayar.xlsx');
        case 'mobile-pulsa-ppob-pascabayar':
          return require('@/assets/samples/Sample-Import-Report-MP-PPOB-Pascabayar.xlsx');
        case 'mobile-pulsa-voucher':
          return require('@/assets/samples/Sample-Import-Report-MP-Voucher.xlsx');
        case 'payment-gateway-mbayar':
          return require('@/assets/samples/Sample-Import-Report-M-Bayar.xlsx');
        case 'ultra-voucher':
          return require('@/assets/samples/Sample-Import-Report-Ultra-Voucher.xlsx');
        case 'payment-gateway-dipay':
          return require('@/assets/samples/Sample-Import-Payment-Gateway-Dipay.xlsx');
        default:
          return '';
      }
    },
    boldRows() {
      return ["App Content", "Deposit", "Finance"];
    },
  },
  methods: {
    handleImportSuccess(message) {
      this.importSuccess = true;
      this.importMessage = message;
    
      setTimeout(() => {
        this.importSuccess = false;
        this.importMessage = '';
      }, 3000);
    }, 
    async confirmImportFin() {
      try {
        const formData = new FormData();
        formData.append('file', this.formImportFinance.file);
        formData.append('type', this.formImportFinance.type);

        const response = await axios.post('/api/finance/transactions/import', formData);

        if (response.data.data.code === 200) {
          console.log('Import successful. Server response:', response.data);
          // Display success message
          this.$message({
            title: 'Success',
            type: 'success',
            message: 'Import data completed.',
          });
          
          // reset to null
          this.formImportFinance = {
            type: '',
            file: null
          };

          this.showSuccessModal = true; // Show success modal
        } else {
          console.error('Import failed. Server response:', response.data);
          // Check if the error object exists and contains the expected properties
          if (response.data.error || response.data.error.code === 400 || response.data.error.title.includes("Some rows are missing the 'order_no' column.")) {
            // Display error message for missing columns
            this.$message({
              title: 'Error',
              type: 'error',
              message: 'Some rows are missing the "order_no" column.',
            });
          } else {
            // Display generic error message
            popupErrorMessages('Import failed. Please try again.', 'error');
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        // new
        this.$message({
          title: 'Error',
          type: 'error',
          message: 'Some rows are missing the "order_no" column.',
        });
        this.formImportFinance = {
          type: '',
          file: null
        };
        popupErrorMessages('Please try again later.', 'error');
      }
    },
    resetImportState() {
      this.showSuccessModal = false; // reset
    },
    validateForm() {
      return this.formImportFinance.type && this.formImportFinance.file;
    },
  },
};
</script>
<style>
.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}
p.thicker {
  font-weight: 900;
}
</style>